.exhibition {
  position: relative;

  @include media-tablet-h {
    height: 51.4375rem; }

  @include media-desktop-1440 {
    height: 54.9375rem; }

  .cont {
    position: relative;
    display: flex;
    flex-direction: column;

    @include media-tablet-h {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      column-gap: 7.9375rem;
      height: 100%; }

    @include media-desktop {
      column-gap: 7.4375rem; }

    @include media-desktop-1440 {
      column-gap: 8.375rem; } }

  &-left {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    padding: 3.5rem 0;

    @include media-tablet {
      row-gap: 2.5rem;
      padding: 5rem 0; }

    @include media-tablet-h {
      width: 28.1875rem; }

    @include media-desktop-1440 {
      width: 32.875rem; }

    @include media-desktop-1600 {
      row-gap: 3.5rem; }

    &__title {
      @include h36;
      max-width: 30.625rem;
      text-transform: uppercase;

      @include media-desktop {
        max-width: none; }

      @include media-desktop-1440 {
        @include h48; } }

    &__desc {
      display: flex;
      flex-direction: column;
      row-gap: 0.75rem;

      @include media-tablet {
        row-gap: 1.5rem; }

      ul {
        @include lreset;
        @include fs14;
        display: flex;
        flex-direction: column;
        row-gap: 1.25rem;
        font-weight: 600;
        color: $dark-grey;

        @include media-tablet {
          @include fs18;
          flex-direction: row;
          flex-wrap: wrap;
          column-gap: 1.375rem; }

        @include media-tablet-h {
          @include fs20;
          flex-direction: column; }

        @include media-desktop-1440 {
          @include fs24; } }

      li {
        display: flex;
        align-items: center;
        padding: .5rem 1rem;
        min-height: 3.375rem;
        background: $grey-f0;
        border-radius: 1rem;

        @include media-tablet {
          width: calc( 50% - 0.75rem );
          min-height: 4rem;
          padding: .5rem 1.25rem; }

        @include media-tablet-h {
          width: 100%;
          min-height: 4.25rem;
          padding: .5rem 2.5rem; } } } }

  &-right {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    width: 42%;
    height: 51.4375rem;
    border-radius: 6.25rem 0 0 0;
    background: $red;

    @include media-tablet-h {
      display: flex; }

    @include media-desktop {
      width: 50%; }

    @include media-desktop-1440 {
      position: absolute;
      top: 0;
      right: 0;
      height: 54.9375rem;
      margin-right: 0; }

    @include media-desktop-1600;

    @include media-desktop-1920;

    &__wrapper {
      display: flex;
      padding-top: 6.25rem;
      border-radius: 6.25rem 0 0 0; }

    img {
      object-fit: cover;
      object-position: center;
      border-radius: 6.25rem 0 0 0; } } }
