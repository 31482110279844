.netw {
  .cont {
    @include media-desktop {
      display: grid;
      grid-template-columns: 17.375em 1fr;
      grid-template-rows: 1fr auto;
      column-gap: 3.75em;
      padding-left: 2.5em;
      padding-right: 2.5em; } }

  &__info {
    max-width: 100em;
    width: 100%;
    padding: 0 2.5em;
    margin: 0 auto;
    margin-top: 3.5em;
    position: relative;

    &-wrap {
      border: 1px solid $red;
      border-radius: 2.5em;
      padding: 1.5em;

      @include media-tablet {
        padding: 2.5em; } }

    &-title {
      @include h18;
      color: $red;
      margin-bottom: 0.5em;
      padding-right: 3em;

      @include media-tablet {
        @include h24;
        padding-right: 0; }

      @include media-desktop {
        @include h36; } }

    &-text {
      @include p14;
      max-width: 43em;

      @include media-desktop {
        @include p18; } }

    &-close {
      position: absolute;
      cursor: pointer;
      width: 1em;
      height: 1em;
      right: 4em;
      top: 1.5em;

      @include media-tablet {
        right: 5em;
        top: 3em; }

      @include media-desktop {
        width: 2.0625em;
        height: 2.0625em;
        top: 6em; } } }

  &__main {
    border-top: 0.0625em solid $grey-c0;
    padding-bottom: 4em;
    padding-top: 4em;

    @include media-desktop {
      grid-column: 2;
      border-top: 0;
      padding-top: 5em; } }

  &__header {
    padding: 1.5em 0;
    background-color: #ffffff;
    border-radius: 0 0 0.5em 0.5em;

    @include media-tablet {
      padding: 2.5em 0;
      border-radius: 0 0 1em 1em; }

    @include media-desktop {
      // padding: 5em 0
      padding: 4em 0;
      border-radius: 0 0 1.5em 1.5em; }

    .cont {
      // padding-left: 2.5em
      // padding-right: 2.5em
      // align-items: center
      display: flex;
      flex-direction: column; }

    .btn--red-underline {
      height: auto;
      font-size: 1.125rem;
      line-height: 1.25;

      // @include media-desktop
      //   grid-template-columns: auto 1fr
      //   justify-items: start

      // .btn--red-underline
      //   +fs14

      //   @include media-tablet-h
 } }      //     +fs18

  &__top {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-bottom: 1.5rem;

    @include media-tablet {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem; }

    @include media-tablet-h {
      justify-content: flex-start;
      column-gap: 2rem;
      margin-bottom: 2rem; }

    @include media-desktop {
      grid-template-columns: auto 1fr;
      justify-items: start; } }

  &__title {
    @include reset;
    @include h24;

    @include media-tablet {
      @include h48;
      width: 28.5rem;
      margin-bottom: 0; }

    @include media-desktop {
      width: 58rem;
      @include h64; } }

  &__desc {
    @include fs18;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    font-weight: 600;

    @include media-tablet {
      row-gap: 0.75rem; }

    @include media-tablet-h {
      max-width: 58rem; }

    p {
      margin: 0;

      br {
        display: none;

        @include media-tablet-h {
          display: block; } } }

    &-note {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      font-weight: 400;
      color: $grey-89;

      @include media-tablet {
        flex-direction: row;
        column-gap: 0.5rem;
        margin-bottom: 1.25rem; } } }

  &__filter-title {
    @include h24;

    @include media-tablet {
      grid-column: 1;
      grid-row: 1; }

    @include media-desktop {
      @include h36;
      grid-column: auto; } }

  &__panel-wrapper {
    @include media-tablet {
      grid-column: 2/5;
      grid-row: 1; }

    @include media-desktop {
      grid-column: auto; } }

  .filter-panel {
    // открыть после появления функционала
    // display: flex
    display: none;
    justify-content: space-between;
    width: 17.5em;
    padding: 0.25em;
    border-radius: 0.5em;
    background-color: #eef0f0;

    @include media-desktop {
      width: 18.5625em; }

    &__btn {
      padding: 0.5625rem 0.75rem;
      min-width: auto;
      border: 1px solid #eef0f0;

      @include media-desktop {
        height: auto;
        padding: 0.5rem 0.75rem;
        font-size: 1.125em;
        line-height: 1; }

      &:hover {
        background-color: $red;
        border-color: $red;
        color: #fff; }

      &--active {
        border-color: $red;
        background-color: #fff;
        color: $red; } } }

  &__header-filters {
    display: none;
    row-gap: 0.75em;

    @include media-tablet {
      margin-top: 1.25em;
      grid-template-columns: 1fr 13.5em;
      column-gap: 1.875em; }

    @include media-desktop {
      display: grid;
      grid-template-columns: auto 1fr 1fr;
      grid-column: 1/3;
      grid-row: 1;
      margin-top: 0;
      padding-top: 4em; }

    &--mobile {
      display: grid;
      margin-bottom: 1.25em;
      row-gap: 0.75em;

      @include media-tablet {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr);
        align-items: center;
        gap: 1.25em 0.75em;
        margin-bottom: 0; }

      @include media-desktop {
        display: none; }

      .netw-filter__reset-btn {
        @include media-tablet {
          grid-column: 3/5;
          grid-row: 3; } } } }

  &__search {
    position: relative;
    width: 100%;
    padding: 0.8571em 1.3571em;
    padding-right: 4em;
    border-radius: 0.25em;

    &-wrapper {
      position: relative;

      @include media-tablet {
        grid-column: 1/5;
        grid-row: 2; }

      @include media-desktop {
        grid-column: auto;
        grid-row: 1; }

      &::after {
        content: "";
        position: absolute;
        right: 1em;
        top: 50%;
        transform: translateY(-50%);
        width: 1em;
        height: 1em;
        z-index: 2;
        background-image: url("../images/search-white-icon.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; }

      &::before {
        content: '';
        position: absolute;
        width: 3em;
        height: 100%;
        right: 0;
        background: $color-red;
        border-bottom-right-radius: 0.25em;
        border-top-right-radius: 0.25em;
        z-index: 1; } } }

  &__filter-btn {
    @include fs14;
    position: relative;
    padding: 0.9285em 0;
    display: block;
    width: 100%;
    color: $grey-88;
    font-weight: 800;
    background-color: $grey-e5;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;

    @include media-tablet {
      border-radius: 0.2857em;
      grid-column: 1/3;
      grid-row: 3; }

    @include media-desktop {
      display: none; }

    &::after {
      content: "";
      position: absolute;
      right: 1.3571em;
      top: 50%;
      transform: translateY(-50%);
      width: 1.4285em;
      height: 1.4285em;
      background-image: url("../images/filter-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; } }

  &__filter {
    padding-top: 4em;

    @include media-tablet {
      padding-top: 2.5em; }

    @include media-desktop {
      grid-column: 1;
      padding-top: 5em; }

    &>.netw-filter__reset-btn {
      margin-bottom: 0;
      margin-top: 0.75rem;

      @include media-desktop {
        display: none; } } }

  &__group {
    margin-top: 4em;

    &:nth-child(1) {
      margin-top: 0; }

    &-header {
      @include h24;
      font-weight: 800;

      @include media-desktop {
        @include h36; }

      span {
        color: $color-red; } }

    &-body {
      padding-top: 2.5em;
      display: grid;
      row-gap: 2.5em;
      grid-template-columns: 1fr;

      @include media-tablet {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row;
        column-gap: 1.875em;
        grid-row-gap: 1.875em; }

      @include media-tablet-h {
        row-gap: 2em;
        grid-template-columns: repeat(6, 0.5fr); }

      @include media-desktop-1440 {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 2em; } } }

  .stands-item {
    &--general {
      @include media-tablet-h {
        grid-template-columns: (25.5625em 1fr); }

      @include media-desktop {
        grid-column: auto / span 6;
        grid-template-columns: 19.1875em 1fr; }

      @include media-desktop-1440 {
        grid-template-columns: 19.1875em 1fr; }

      .stands-item__content {
        @include media-tablet-h {
          grid-template-columns: 1fr 12.9375em;
          grid-template-areas: "logo logo" "title title" "desc desc" "contacts meeting" "contacts exchange";
          column-gap: 1em; }

        @include media-desktop-1440 {
          grid-template-columns: 1fr 12.9375em 12.9375em;
          grid-template-areas: "logo logo logo" "title title title" "desc desc desc" "contacts meeting exchange";
          column-gap: 0.625em; } }

      .stands-item__contacts {
        @include media-tablet-h {
          align-self: end; }

        @include media-desktop-1440 {
          align-self: center; } } }

    &--platinum {
      @include media-tablet {
        grid-template-columns: 9.75em 1fr;
        column-gap: 1.5em; }

      @include media-desktop {
        grid-column: auto / span 3; }

      @include media-desktop-1440 {
        grid-column: auto / span 2;
        grid-template-columns: 13.6875em 1fr; }

      .stands-item {
        &__logo {
          left: 1.5em;

          @include media-desktop-1440 {
            left: 2.5em; } }

        &__logo-wrapper {
          display: block;

          @include media-desktop {
            padding: 0.5625em; }

          @include media-desktop-1440 {
            padding: 0.75em; }

          img {
            @include media-desktop {
              width: 5.625em;
              height: 5.625em; }

            @include media-desktop-1440 {
              width: 7.1275em;
              height: 7.1275em; } } } } }

    &--gold,
    &--silver,
    &--bronze,
    &--agent {
      @include media-tablet-h {
        grid-column: auto / span 2; }

      @include media-desktop-1440 {
        grid-column: auto / span 1; }

      margin-bottom: 0; }

    &__btn {
      a {
        @include media-desktop-1440 {
          padding: 0.857em 0.5em; } } } }

  &__more-items-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.5em;
    margin-top: 2em;
    background-color: #fff;
    border-radius: 0.25rem;

    span {
      @include fs14;
      position: relative;
      padding-right: 1.5em;
      background-image: url("../images/stand-arrow.svg");
      background-repeat: no-repeat;
      background-position: right center; } }

  &__result {
    border-top: 0.0625em solid $grey-c0;
    padding-bottom: 4em;
    padding-top: 4em;

    @include media-desktop {
      grid-column: 2;
      grid-row: 2;
      border-top: 0;
      padding-top: 5em; }

    &-title {
      @include reset;
      @include h36; }

    hr {
      background: #C0C0C0;
      margin-top: 1.5em;
      margin-bottom: 1.5em; }

    &-search {
      @include reset;
      @include h24;
      margin-bottom: 2.5em;

      span {
        color: $color-red; } } } }

.how-to-use {
  margin-bottom: 1.5625em;

  .swiper-wrapper {
    align-items: flex-end; }

  &__slide {
    display: flex;
    flex-direction: column;

    &-title {
      margin-bottom: 0.75rem;
      @include h24;

      @include media-tablet {
        @include h36; } }

    &-desc {
      height: 5.625rem;
      @include fs14;

      @include media-tablet {
        height: 4.125em;
        @include fs18; }

      @include media-desktop {
        height: 2.75em; } }

    &-img {
      img {
        display: block; } } }

  &__body {
    padding: 0 1.25em;
    margin-bottom: 1.25em;

    @include media-tablet {
      padding: 0 2.5em;
      margin-bottom: 1.5625em; } }

  &__slider {
    &-pagination {
      left: 0;
      bottom: -44px;
      width: 100%; }

    &-nav {
      display: none;
      position: absolute;
      top: 50%;
      z-index: 5;
      transform: translateY(-50%);
      width: 2.5em;
      height: 3.5em;
      background-color: #fff;
      background-image: url(@public/images/slider-arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0.5em 1em;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5; }

      @include media-tablet-h {
        display: block; } }

    &-prev {
      left: -1.5em;
      border-radius: 0.75em 0 0 0.75em; }

    &-next {
      right: -1.5em;
      border-radius: 0.75em 0 0 0.75em;
      transform: translateY(-50%) rotate(180deg); } }

  .swiper-pagination-bullet {
    width: 0.625em;
    height: 0.625em;
    margin: 0 0.375em;
    vertical-align: top;
    background-color: #fff;
    opacity: 1; }

  .swiper-pagination-bullet-active {
    background-color: $red; } }
