.rating {
  display: grid;
  padding-top: 5rem;
  padding-bottom: 4rem;

  color: $color-black;

  background-color: $grey-f0;

  @include media-tablet {
    padding-top: 7.5rem;
    padding-bottom: 5rem; }

  @include media-desktop {
    padding-top: 5rem; }

  &__cont {
    display: grid;
    row-gap: 2.5rem;

    @include media-tablet {
      row-gap: 4rem; }

    @include media-tablet-h {
      row-gap: 5rem; } }

  &__header {
    @include media-tablet-h {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1.875em; }

    @include media-desktop {
      grid-template-columns: 32.1875em 1fr;
      column-gap: 5.625em; } }

  &__title {
    @include h36;

    font-weight: 800;

    @include media-tablet {
      @include h48; } }

  &__user {
    margin-top: 3em;

    @include media-desktop {
      margin-top: 2.5em; }

    &-title {
      @include fs18;

      display: inline-block;
      padding: 0.666em 1.333em;

      background-color: $grey-ee;
      border-top-right-radius: 0.666em;
      border-top-left-radius: 0.666em; } }

  &__desc {
    margin-top: 1.5em;

    @include media-tablet {
      margin-top: 0; }

    p {
      @include p14;

      margin: 0.857em 0;

      @include media-tablet {
        @include p18;

        margin: 0.666em 0; }

      @include media-tablet-h {
        @include p14;

        margin: 1em 0; }

      @include media-desktop {
        @include p16;

        margin: 0.75em 0; }

      &:first-of-type {
        margin-top: 0; }

      &:last-of-type {
        margin-bottom: 0; } } }

  &__links {
    margin-top: 1em;

    @include media-tablet {
      display: flex;
      justify-content: space-between; }

    a {
      color: $color-red;
      text-decoration: underline;

      &:hover {
        text-decoration: none; } }

    &-item {
      @include fs14;

      margin-top: 1.142em;

      @include media-tablet {
        margin-top: 0; }

      @include media-desktop {
        @include fs16; }

      &--1 {
        position: relative;

        padding-left: 2.125em;

        &::before {
          content: "";

          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);

          width: 1.5em;
          height: 1.5em;

          background-image: url(@public/images/rating-leader.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center; } } } }

  &__main {
    background-color: $grey-e5;
    border-radius: 0.75rem; }

  &__body {}

  &__list {
    @include lreset;

    padding: 0 1.5rem;

    background-color: $white;
    border-radius: 0.75rem; }

  &__item {
    display: grid;
    grid-template-areas: "star place score" "user user user";
    grid-template-columns: auto auto 1fr;
    column-gap: 0.75rem;
    row-gap: 0.75rem;
    align-items: center;
    padding: 1.125rem 0;
    border-bottom: 1px solid $grey-c0;

    @include media-tablet {
      grid-template-areas: "place star user score";
      grid-template-columns: 3.75rem 1.5rem 1fr auto;
      column-gap: 1.25rem;
      padding: 0.75rem 0; }

    @include media-tablet-h {
      grid-template-areas: "place star user company score";
      grid-template-columns: 3.75rem 1.5rem 15.625rem 1fr auto; }

    @include media-desktop {
      grid-template-areas: "place star user company score";
      grid-template-columns: 5.625rem 1.5rem 14.625rem 1fr auto;
      column-gap: 1.5rem;
      padding: 1.25rem 1.875rem; }

    &:last-child {
      border-bottom: none; }

    &--leader {
      .rating__item-place {
        color: $red; }

      .rating__item-star {
        svg {
          opacity: 1;

          path {
            stroke: $red; } } } }

    &--active {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      background-color: $color-red;

      @include media-desktop {
        position: relative;

        margin-left: 0;
        margin-right: 0;
        padding-left: 1.875rem;
        padding-right: 1.875rem;

        background-color: transparent; }

      &::before {
        @include media-desktop {
          content: "";

          position: absolute;
          left: 0;
          right: 0;
          top: 0.625rem;
          bottom: 0.625rem;

          border-radius: 3.125rem;
          background-color: $color-red; } }

      .rating__item {
        &-star {
          svg {
            path {
              stroke: $white; } } }

        &-place {
          color: $white; }

        &-score {
          color: $white;
          span {
            color: $white; } }
        &-name {
          color: $white; }
        &-company {
          color: $white; } } }

    &-place {
      @include fs18;

      position: relative;

      grid-area: place;

      color: $grey-c0;
      font-weight: 800;

      @include media-tablet {
        @include h24; }

      @include media-desktop {
        @include h36; } }

    &-star {
      position: relative;

      grid-area: star;
      display: flex;

      svg {
        opacity: 0.55; } }

    &-user {
      position: relative;

      display: grid;
      grid-area: user;
      align-items: center;

      @include media-tablet {
        padding-left: 12.3537vw; }

      @include media-tablet-h {
        padding-left: 3.125rem; }

      @include media-desktop {
        column-gap: 1rem;
        grid-template-columns: 3rem 1fr;
        padding-left: 0; } }

    &-img {
      display: none;

      @include media-desktop {
        display: block;
        width: 3rem;
        height: 3rem;

        background-color: $grey-f5;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;

          object-fit: cover;
          object-position: center; } } }

    &-name {
      @include fs14;

      font-weight: 800;

      @include media-desktop {
        @include fs18; } }

    &-company {
      @include fs14;

      position: relative;

      grid-area: company;
      display: none;

      @include media-tablet-h {
        display: block; }

      @include media-desktop {
        @include fs16; }

      @include media-desktop-1440 {
        padding-left: 3.75vw; }

      @include media-desktop-1600 {
        padding-left: 6.75vw; } }

    &-score {
      @include fs11;

      position: relative;

      grid-area: score;

      font-weight: 800;
      text-align: right;

      @include media-tablet {
        @include fs14; }

      span {
        display: block;

        font-size: 1.6364em;
        color: $color-red;

        @include media-tablet {
          font-size: 1.7143em; } } } }

  &__pagination {
    display: none;

    @include media-tablet {
      display: block; }

    &-list {
      @include lreset;

      display: flex;
      flex-wrap: no-wrap;
      justify-content: space-around;
      align-items: center;

      list-style: none; }

    &-separator {
      display: block;
      height: 1.625rem;
      width: 1px;

      background-color: $grey-c0; }

    &-item {}

    &-link {
      display: block;
      padding: 0.75rem;

      color: $grey-c0;
      font-weight: 800;

      &--active {
        color: $color-red; } } } }
