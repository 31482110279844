.event-create-done {
  display: grid;
  row-gap: 1.5em;

  @include media-tablet {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2.5em;
    grid-template-rows: auto auto auto 1fr;
    row-gap: 2.5em; }

  &--decline-popup {
    @include media-tablet {
      grid-template-rows: auto 1fr; } }

  &--error-popup {
    @include media-tablet {
      grid-template-rows: auto; }

    .event-create-done__card-alert {
      &-content {
        display: grid;
        row-gap: 1.25em;
        @include media-tablet {
          padding-right: 3em; } }

      &-title {
        color: $color-red;
        text-align: left; }

      &-desc {
        @include p14; } } }

  hr {
    &.event-create-done__divider {
      &--first {
        @include media-tablet {
          display: none; } }

      &--second {
        @include media-tablet {
          grid-column: 1 / span 2;
          grid-row: 2; } } } }

  &__title {
    @include fs18;

    @include media-tablet {
      grid-row: 3px;
      grid-column: 1; }

    div {
      font-size: 0.7778em;
      color: $color-red;
      font-weight: 800; } }

  &__card {
    position: relative;

    display: grid;
    row-gap: 1em;
    padding: 1.5em;
    margin-left: -0.5em;
    margin-right: -0.5em;

    border-radius: 0.5em;
    background-color: $grey-f5;

    @include media-tablet {
      grid-column: 1 / span 2;
      grid-row: 1;
      grid-template-columns: 1fr auto 1fr auto 1fr;
      column-gap: 1em;
      row-gap: 2em;
      margin-left: 0;
      margin-right: 0; }

    hr {
      width: 0.0625em;
      height: 100% !important;
      background-color: $grey-e5 !important; }

    &-header {
      display: grid;
      row-gap: 1em; }

    &-title {
      display: grid;

      font-weight: 800;

      p {
        @include reset;
        @include fs18; }

      span {
        color: $color-red;
        font-size: 1.2em; } }

    &-user {
      display: grid;
      row-gap: 0.75em;

      @include media-tablet {
        grid-template-rows: auto 1fr; } }

    &-status {
      @include fs14;

      font-weight: 800;

      border: 1px solid $dark-grey;
      border-radius: 4px; }

    &-buttons {
      display: grid;
      row-gap: 0.5em;
      padding-top: 1em;

      @include media-tablet {
        padding-top: 0;
        grid-column: 1 / span 5;
        grid-template-columns: repeat(3, max-content);
        column-gap: 0.5em; }

      &>* {
        @include fs14;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.5714em;

        @include media-tablet {
          padding-left: 1.7143em;
          padding-right: 1.7143em; } } }

    &-desc {
      display: grid;
      row-gap: 0.5em;
      grid-template-rows: auto 1fr; }

    &-alert {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      display: none;
      row-gap: 2em;
      align-items: center;
      padding: 2.5em;

      background-color: $grey-f5;

      overflow: hidden;

      &--open {
        display: grid;

        @include media-tablet {
          display: flex; } }

      @include media-tablet {
        justify-content: space-between; }

      &-title {
        @include fs18;

        font-weight: 800;
        text-align: center; }

      &-buttons {
        display: grid;
        row-gap: 0.5em;

        @include media-tablet {
          grid-template-columns: repeat(2, max-content);
          column-gap: 0.5em; } }

      &-decline {
        background-color: $grey-e5; } } }

  &__date {
    @include fs11;

    color: $grey-88; }

  &__user {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 0.75em;
    row-gap: 0.25em;
    grid-template-rows: auto 1fr;

    &-title {
      @include fs11;

      color: $grey-88; }

    &-img {
      width: 2.5em;
      height: 2.5em;
      grid-row: 1 / span 2;

      img {
        width: 100%;
        height: 100%;

        border-radius: 50%;
        object-fit: cover;
        object-position: center; }
      .void {
        width: 100%;
        height: 100%;

        border-radius: 50%;
        background: #f5f5f5; } }

    &-name {
      @include fs14;

      grid-column: 2;

      font-weight: 800; }

    &-company {
      @include fs11;

      grid-column: 2;
      grid-row: 2; } }

  &__desc {
    &-title {
      @extend .event-create-done__user-title; }

    &-text {
      @include fs14;

      overflow: hidden; } }

  &__cancel-btn {
    color: $color-red;

    border-radius: 4px;
    background-color: $grey-e5; }

  &__other-time-btn {
    color: $dark-grey; }

  &__text {
    @include fs14;

    @include media-tablet {
      grid-column: 2;
      grid-row: 3 / span 2; }

    p {
      margin: 0.5714em 0; }

    &:first-child {
      margin-top: 0; }

    &:last-child {
      margin-bottom: 0; } }

  &__buttons {
    display: grid;
    row-gap: 0.75em;

    @include media-tablet {
      grid-row: 4;
      grid-column: 1;
      margin-top: -1em;
      column-gap: 1.5em;

      grid-template-columns: repeat(2, max-content); } }

  &__to-calendar-link {
    display: inline-block;
    margin: 0 auto;

    text-align: center;
    color: $grey-88;
    text-decoration: underline;

    @include media-tablet {
      @include fs14;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.5714em; }

    &:hover {
      text-decoration: none; } }

  &__decline-disclaimer {
    @include p14;

    grid-column: span 2;

    color: $grey-88;

    span {
      color: $color-red; } } }
