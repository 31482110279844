.calendar-page {
  display: grid;
  row-gap: 2.375em;

  &__header {
    display: flex;
    align-items: center;

    &-today {
      padding: 0.75em 1em;
      margin-left: 2.5em;

      color: $grey-88;

      border: 0.0625em solid $grey-88;
      border-radius: 0.25em;
      background-color: #ffffff; }

    &-week {
      position: relative;

      margin-left: 2.5em;
      padding: 0.75em 3.125em;

      &-arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1.875em;

        background-image: url("../images/calendar-arrow.svg");
        background-size: 0.5em 0.875em;
        background-position: center;
        background-repeat: no-repeat;

        &--prev {
          left: 0; }

        &--next {
          right: 0;

          transform: rotate(180deg); } } } }

  &__meet-request {
    @include fs14;

    display: flex;
    align-items: center;
    padding: 1em 1.4286em;
    margin-left: auto;
    font-weight: bold;

    color: #fff;

    border-radius: 0.25em;
    background-color: $color-red;

    &-icon {
      margin-right: 0.7143em;
      width: 0.8571em;
      height: 1.2857em;
      display: inline-block;

      background-image: url("../images/calendar-header-meet.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; } } }
