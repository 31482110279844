.user {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5em 1.25em;

  background-color: $grey-f5;

  @include media-tablet {
    padding: 2.5em 1.875em; }

  @include media-desktop {
    padding: 2.5em; }

  &__inner {
    width: 100%;
    margin: 0 auto;
    max-width: 95em;
    display: grid;
    row-gap: 2.5em; }

  &__header {
    display: grid;
    row-gap: 1em;

    @include media-tablet {
      row-gap: 1.5em; }

    @include media-desktop {
      grid-template-columns: 1fr auto auto;
      column-gap: 2.5em; }

    hr {
      @include reset;

      border: none;
      background-color: $grey-e5;
      height: 0.0625em;
      width: 100%;

      @include media-desktop {
        height: 100%;
        width: 0.0625em; } } }

  &__body {
    display: grid;
    row-gap: 2.5em;

    @include media-desktop {
      grid-template-columns: 1fr 31.91%;
      column-gap: 2em;
      align-items: start; } }

  &__column {
    display: grid;
    row-gap: 2.5em;

    @include media-desktop {
      row-gap: 2em; }

    &--left {
      @include media-desktop {
        grid-column: 1; } }
    &--right {
      @include media-desktop {
        grid-column: 2; } } }

  &__block {
    padding: 1.5em;

    background-color: #ffffff;
    border-radius: 0.5em;

    @include media-tablet-h {
      padding: 2.5em; } }

  &__info {
    display: grid;
    row-gap: 0.25em;

    @include media-tablet {
      display: grid;
      grid-template-columns: 7.5em 1fr;
      grid-template-rows: auto 1fr;
      column-gap: 2.5em;
      row-gap: 0.75em; } }

  &__avatar {
    width: 7.5em;
    height: 7.5em;

    margin-bottom: 0.75em;

    @include media-tablet {
      grid-column: 1;
      grid-row: 1 / span 2;
      margin-bottom: 0; }

    &--empty {
      border-radius: 50%;
      background-color: $grey-f5; }

    img {
      @include round-img; } }

  &__name {
    @include reset;
    @include fs18;

    font-weight: 800;

    @include media-tablet {
      @include h36;

      grid-column: 2;
      grid-row: 1; } }

  &__position {
    @include fs11;

    color: $grey-88;

    @include media-tablet {
      @include fs18;

      grid-column: 2;
      grid-row: 2; } }

  &__buttons {
    display: grid;
    row-gap: 0.75em;

    @include media-tablet {
      grid-template-columns: repeat(3, max-content);
      column-gap: 1.5em; }

    @include media-desktop {
      row-gap: 0.75em;
      grid-template-columns: 1fr; } }

  &__company {
    display: grid;
    row-gap: 2.5em;

    @include media-tablet {
      grid-template-columns: 1fr auto;
      column-gap: 2.5em; }

    &-info {
      display: grid;
      row-gap: 0.75em;

      @include media-tablet {
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 2.5em;
        row-gap: 1em;
        align-items: start; } }

    &-img {
      width: 6.25em;
      height: 4.1875em;
      padding: 0.5em;
      margin-bottom: 0.25em;

      border-radius: 0.25em;
      border: 0.0625em solid $grey-e5;

      @include media-tablet {
        grid-column: 1;
        grid-row: 1 / span 2;

        margin-bottom: 0; }

      img {
        @include contain-img; } }

    &-name {
      @include reset;
      @include fs16;

      font-weight: 800;

      @include media-tablet {
        @include fs18;

        grid-column: 2;
        grid-row: 1; } }

    &-directions {
      @include lreset;

      display: flex;
      flex-wrap: wrap;

      @include media-tablet {
        grid-column: 2;
        grid-row: 2; }

      li {
        @include fs11;

        position: relative;

        margin-right: 1.0909em;

        color: $grey-88;

        @include media-tablet {
          @include fs16; }

        &:nth-child(n+2) {
          &::before {
            content: "";

            position: absolute;
            left: -0.659em;
            top: 50%;
            transform: translateY(-50%);

            width: 0.2273em;
            height: 0.2273em;

            background-color: $grey-88;
            border-radius: 50%; } } } }

    &-link {
      @include fs14;

      color: $red;

      @include media-tablet {
        align-self: center; } } }

  &__about {
    display: grid;
    row-gap: 1.5em;

    &-title {
      @include reset;
      @include h24; }

    &-desc {
      &--empty {
        @include p18;

        padding: 0.6667em;

        color: $grey-c0;

        border: 1px dashed $grey-c0;
        border-radius: 0.2222em; }

      p {
        @include fs14;

        margin: 1.4286em 0;

        @include media-tablet {
          @include fs18;

          margin: 1.1111em 0; }

        &:first-child {
          margin-top: 0; }

        &:last-child {
          margin-bottom: 0; } } }

    &-directions {
      display: grid;
      row-gap: 1em;

      &-title {
        @include reset;
        @include fs18;

        font-weight: 800; }

      &-list {
        @include reset;

        color: $grey-88; } } }

  &__props {
    display: grid;
    row-gap: 2em;

    &-title {
      @include fs18; }

    &-list {
      @include lreset;

      display: flex;
      flex-wrap: wrap;
      margin-bottom: -1.125em;

      @include media-tablet {
        margin-bottom: -0.75em; } }

    &-item {
      @include fs11;

      padding: 0.4545em 0.8182em;
      margin-right: 1.0909em;
      margin-bottom: 1.6364em;

      background-color: $grey-f5;
      border-radius: 0.3636em;

      @include media-tablet {
        @include fs16;

        padding: 0.3125em 0.5625em;
        margin-right: 0.75em;
        margin-bottom: 0.75em;

        border-radius: 0.25em; } } } }
