.profile-steps {
  display: grid;
  row-gap: 2.5em;

  button {
    cursor: pointer; }

  &__title {
    display: grid;
    grid-template-columns: 26.3125em 1fr;
    column-gap: 7.1875em;
    align-items: center;

    &-main {
      @include h36; }

    &-desc {
      @include p16; } }

  hr {
    width: 100%;
    margin: 0;
    height: 0.0625em;
    border: none;
    background-color: $grey-88; }

  &__step {
    display: grid;
    row-gap: 1.5em;

    &-title {
      @include h24;

      span {
        color: $color-red; } }

    &-body {
      display: grid;
      row-gap: 1.5em;

      &--step-3 {
        max-width: 36.125em;

        .input-ac {
          max-width: 29em; }

        .btn {
          justify-self: start; } } }

    &-footer {
      display: flex;
      padding-top: 1.5em;
      justify-content: center;

      .btn {
        min-width: 16.1429em; } } }

  &__text {
    @include p14;

    &--no-company {
      @include fs18;

      font-weight: 800; }

    ul {
      @include lreset; }

    li {
      position: relative;

      padding-left: 0.6429em;

      &::before {
        content: "";

        position: absolute;
        top: 0.7em;
        left: 0;

        width: 3px;
        height: 3px;

        border-radius: 50%;
        background-color: $dark-grey; } } }

  &__step-2 {

    &-question {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center; }

    &-buttons {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 0.5em;

      .btn {
        &--grey {
          color: $dark-grey;
          background-color: $grey-e5; }

        &--check {
          font-weight: 400;

          color: $dark-grey;
          text-decoration: underline; } } }

    &-desc {
      p {
        margin: 0.625em;

        &:first-child {
          margin-top: 0; }

        &:last-child {
          margin-bottom: 0; } } } }

  &__group {
    display: grid;
    row-gap: 1.5em;
    padding-top: 1em;

    &-header {
      display: grid;
      grid-template-columns: 1fr auto;
      row-gap: 1.5em;
      column-gap: 1.5em;
      align-items: center; }

    &-title {
      @include fs18;

      grid-column: 1;
      grid-row: 1;

      font-weight: 800;

      span {
        color: $color-red; } }

    &-req {
      @include fs14;

      grid-column: 2;
      grid-row: 1;

      color: $color-red; }

    &-desc {
      @include fs18;

      grid-column: 1 / span 2;
      grid-row: 2;

      color: $grey-88;

      &--dashed {
        @include p14;

        padding: 0.7857em;

        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23C0C0C0FF' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 0.2857em; } }

    &-link {
      @include fs14;

      color: $color-red;

      &:hover {
        text-decoration: underline; } }

    &-body {
      display: flex;
      flex-wrap: wrap;

      label {
        @include fs16;

        padding: 0.3125em 0.5em;
        margin-bottom: 1em;
        margin-right: 0.75em;

        border-radius: 0.25em;
        border: 1px solid $grey-f5;
        background-color: $grey-f5;
        user-select: none;
        cursor: pointer; }

      input:checked + label {
        border: 1px solid $color-red; }

      &--language {
        label {
          @include fs18;

          padding: 0.4444em 0.8889em; } }

      &--company {
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: 17.6875em;
        align-items: center; } }

    &-item {
      @include fs16;

      padding: 0.3125em 0.5em;
      margin-bottom: 1em;
      margin-right: 0.75em;

      color: $grey-88;

      border-radius: 0.25em;

      border: 1px solid $grey-f5;
      background-color: $grey-f5;
 } }      // user-select: none

  &__company {
    display: grid;
    column-gap: 2em;
    grid-template-columns: auto 1fr;
    align-items: center;

    &-status {
      @include fs14;

      background-color: $grey-e5;
      border-radius: 0.2857em;

      &--moderate {
        padding: 0.7143em;

        color: $grey-88; }

      &--declined {
        padding: 0.6429em;

        color: $color-red;

        border: 1px solid $color-red; } }

    &-img {
      width: 9.375em;
      height: 6.25em;
      padding: 0.75em;

      border: 0.0625em solid $grey-ee;
      border-radius: 0.25em;

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center; } }

    &-name {
      @include fs18; }

    &-links {
      display: flex;

      a {
        @include fs14;

        display: inline-block;
        margin-right: 2.2857em;

        color: $color-red;

        &:hover {
          text-decoration: underline; }

        &:last-child {
          margin-right: 0; } } } }

  &__open {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2em;
    align-items: center;

    &-title {
      @include h36; }

    &-desc {
      @include p16; }

    &-link {
      @extend .profile__info-link;

      margin-top: 1.4286em;

      span {
        color: $color-red; }

      &-arrow {
        @extend .profile__info-link-arrow; } } } }
