.select {
  &__wrapper {
    position: relative;

    &::after {
      content: "";

      width: 10px;
      height: 7px;

      position: absolute;
      right: 1em;
      top: 50%;
      transform: translateY(-50%);

      background-image: url("../images/dropdown-arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; } }

  select {
    @include p14;

    height: 2.7143em;
    width: 100%;
    padding: 0 0.8571em;

    background-color: transparent;
    border-radius: 0.25rem;
    border: 1px solid $grey-e5;
    appearance: none;
    user-select: none;

    &::placeholder {
      font-family: $base-font;

      color: $grey-88; }

    &.error {
      outline: none;
      box-shadow: none;

      border-color: $red; }
    option {
      &.bold {
        font-weight: bold;
        &:first-child {
          background: red; } } } } }
