.stand-members {
  display: grid;
  row-gap: 2em;

  @include media-desktop {
    position: sticky;
    top: 78px; }

  &__header {
    display: grid;
    row-gap: 1em;

    @include media-tablet {
      grid-template-columns: 1fr auto; } }

  &__change-link {
    @include fs11;

    align-self: center;

    @include media-tablet {
      grid-column: 2;
      grid-row: 1; }

    a {
      color: $red; } }

  &__title {
    @include h24;

    @include media-tablet {
      grid-row: 1;
      grid-column: 1; } }

  &__desc {
    @include fs14;

    @include media-tablet {
      grid-row: 2;
      grid-column: 1 / span 2; } }

  &__body {
    display: grid;
    row-gap: 2.5em;

    @include media-tablet {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 5em; }

    @include media-desktop {
      grid-template-columns: 1fr; } }

  &__item {
    display: grid;
    row-gap: 0.75em;

    &-body {
      display: grid;
      grid-template-columns: 2.5em 1fr;
      grid-template-rows: repeat(2, max-content);
      column-gap: 0.5em;
      row-gap: 0.25em;
      padding: 0.25em 0; }

    &-name {
      grid-row: 1;
      grid-column: 2; }

    &-position {
      @include fs11;

      grid-row: 2;
      grid-column: 2;

      color: $grey-88; }

    &-img {
      grid-row: 1 / span 2;

      img {
        width: 2.5em;
        height: 2.5em;
        display: block;

        border-radius: 50%;
        object-fit: cover;
        object-position: center; } }

    &-footer {
      display: grid;
      row-gap: 0.75em;

      @include media-tablet {
        row-gap: 0.5em; }

      @include media-tablet-h {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1em; }

      @include media-desktop {
        grid-template-columns: 1fr;
        row-gap: 0.5em; }

      @include media-desktop-1440 {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0.5em; }

      .btn {
        padding: 0;

        height: 2.4286em;

        width: 100%; } } }

  &__footer {
    padding-top: 0.5em;
    border-top: 0.0625em solid $dark-grey; }

  &__more-link {
    @include fs14;

    margin-top: 0.2857em;
    display: flex;
    justify-content: center;
    padding: 0.5714em 0.8571em;

    color: $grey-88; } }
