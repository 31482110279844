.checkbox-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.75rem;
  row-gap: 0.75rem;

  label {
    @include fs16;

    display: block;
    padding: 0.3125em 0.5em;

    border-radius: 0.25rem;
    border: 1px solid $grey-f5;
    background-color: $grey-f5;
    user-select: none;
    cursor: pointer; }

  input:checked + label {
    border: 1px solid $red; } }
