.netw-profile {
  display: grid;
  row-gap: 3.5rem;

  &>hr {
    @include reset;

    width: 100%;
    height: 1px;

    background-color: $grey-c0;

    border: none; }

  &__title {
    display: grid;
    grid-template-columns: 1fr auto;

    h2 {
      @include h24;
      @include reset; } }

  &__edit-btn {
    @include fs14;
    @include reset;

    color: $color-red;

    border: none;
    background-color: transparent;

    &:hover {
      text-decoration: underline; } }

  &__submit-btn {
    justify-self: start;
    min-width: 23.25rem; } }
