.company-create {
  background-color: $grey-f5;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2.5em 0;

  &__title {
    @include reset;
    @include h36; }

  &__grid {
    display: grid;
    row-gap: 2.5em;
    width: 59.6875em;
    margin: 0 auto; }

  .form {
    &__header {
      display: grid;
      row-gap: 0.25em; }

    &__footer {
      display: flex;

      .btn {
        margin-right: 1em; } } }

  &__block {
    @extend .cabinet__block;

    &-title {
      @include fs18;

      font-weight: 800;
      color: $color-red;

      span {
        color: $dark-grey; } }

    &-desc {
      @include fs18; }

    &-req {
      @include fs14;

      color: $color-red; } }

  .textarea {
    &__input {
      height: 11.5em; } }

  &__step-3 {
    &-disclaimer {
      @include fs14;

      padding: 0.8571em;
      margin-bottom: 0.8571em;

      color: $grey-88;

      border-radius: 0.2857em;
      border: 1px solid $grey-e5;
      background-color: $grey-f5;

      ul {
        @include lreset;

        li {
          position: relative;

          padding-left: 0.6429em;

          &::before {
            content: "";

            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            width: 3px;
            height: 3px;

            border-radius: 50%;
            background-color: $grey-88; } } } } }

  &__step-4 {
    &-form {
      grid-template-columns: 1fr auto;
      grid-template-rows: 1fr auto;

      .form__header {
        grid-column: 1;
        grid-row: 1; }

      .form__body {
        grid-column: 2;
        grid-row: 1 / span 2; }

      .form__footer {
        grid-column: 1;
        grid-row: 2; } } }

  &__step-5 {
    &-subtitle {
      @include fs18; }

    &-form {
      hr {
        display: none; }
      .form {
        &__body {
          grid-template-columns: repeat(2, 1fr);
          column-gap: 3.0625em;
          row-gap: 2em; }
        &__group {
          &--span-2 {
            grid-column: auto / span 2; }

          &--links {
            position: relative;

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 3.0625em;
            grid-column: 1 / span 2;

            &::before {
              content: "";

              position: absolute;
              top: 0;
              bottom: 0;
              left: 50%;

              width: 1px;
              background-color: $grey-88; } }

          &--address {
            position: relative;

            &::after {
              content: "";

              position: absolute;
              top: 0;
              bottom: 0;
              right: -1.5625em;

              width: 1px;
              background-color: $grey-88; } } } } } }
  &__avatar {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 11.8125em;

    color: $grey-88;

    background-color: $grey-f5;
    border: 0.0625em dashed #C0C0C0;
    border-radius: 0.25em;

    input[type="file"] {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;

      width: 100%;

      opacity: 0; }

    div {
      &:first-of-type {
        margin-bottom: 0.5em; }

      &:nth-of-type(2) {
        @include fs14; } } }

  &__company-exist {
    @include fs14;

    color: $red;

    a {
      text-decoration: underline;
      color: $red; } } }

// .company-create
//   display: grid
//   grid-template-columns: 1fr 23.625em
//   grid-template-rows: auto 1fr
//   column-gap: 2em
//   row-gap: 2.5em
//   max-width: 79.625em
//   margin: 0 auto

//   &__col
//     display: grid
//     row-gap: 2em
//     grid-row: 2

//     &--left
//       grid-column: 1

//     &--right
//       grid-column: 2
//       align-self: start

//   &__title
//     +h24

//   .textarea
//     height: 13.1429em

//   &__form
//     .form
//       row-gap: 2em

//       &__footer
//         display: flex

//     .btn
//       display: inline-block
//       min-width: 16.1429em
//       width: auto

//     &-row
//       display: grid
//       column-gap: 0.75em

//       &--index
//         grid-template-columns: 1fr 5.8125em

//       &--3fr
//         grid-template-columns: repeat(3, 1fr)

//     &--main
//       .form__group
//         display: grid
//         grid-template-columns: repeat(2, 1fr)
//         column-gap: 0.75em

//       .textarea
//         grid-column: 1 / span 2

//     &--contacts
//       hr
//         @include media-desktop-1600
//           display: none

//       .form
//         &__body
//           @include media-desktop-1600
//             grid-template-columns: repeat(2, 1fr)
//             column-gap: 3.0625em
//             row-gap: 2em

//         &__group
//           &--links
//             @include media-desktop-1600
//               position: relative

//               display: grid
//               grid-template-columns: repeat(2, 1fr)
//               column-gap: 3.0625em
//               grid-column: 1 / span 2

//             &::before
//               @include media-desktop-1600
//                 content: ""

//                 position: absolute
//                 top: 0
//                 bottom: 0
//                 left: 50%

//                 width: 1px
//                 background-color: $grey-88

//           &--address
//             position: relative

//             &::after
//               @include media-desktop-1600
//                 content: ""

//                 position: absolute
//                 top: 0
//                 bottom: 0
//                 right: -1.5625em

//                 width: 1px
//                 background-color: $grey-88

//   &__avatar
//     display: flex
//     flex-direction: column
//     justify-content: center
//     align-items: center
//     height: 12.5em

//     color: $grey-88

//     border: 0.0625em dashed $grey-c0
//     background-color: $grey-f5
//     border-radius: 0.25em

//     span
//       +fs14

//       display: block
//       margin-top: 0.5714em

//   &__spec
//     display: grid
//     row-gap: 1em

//     &-inputs
//       padding-top: 1em

//   &__invite
//     form
//       row-gap: 2em

//     &-submit
//       width: 100%
