.stand-contacts {
  display: grid;
  row-gap: 2em;

  &--unreg {
    row-gap: 1.5em;

    @include media-tablet {
      grid-template-columns: 1fr auto;
      column-gap: 1.5em; } }

  &__show-link {
    width: 100%;

    color: $dark-grey;
    font-weight: 400;

    background-color: $grey-e5;

    @include media-tablet {
      width: auto;
      height: 3em;
      padding: 0 1.7143em; } }

  &__header {
    display: grid;
    row-gap: 1em;

    @include media-tablet {
      grid-template-columns: 1fr auto;
      column-gap: 1em; } }

  &__change-link {
    @include fs11;

    align-self: center;

    a {
      color: $color-red; } }

  &__title {
    @include h24; }

  hr {
    height: 0.0625em;
    margin: 0;

    background-color: $grey-88;
    border: none; }

  &__body {
    display: grid;
    row-gap: 2em; }

  &__office {
    display: grid;
    row-gap: 2em;

    &-item {
      display: grid;
      row-gap: 1em; }

    &-title {
      @include fs18;

      font-weight: 800; }

    &-person {
      display: grid;
      row-gap: 0.25em; }

    &-position {
      color: $grey-88; } }

  &__footer {
    display: grid;
    row-gap: 1em;

    @include media-tablet {
      grid-template-columns: 1fr auto;
      column-gap: 1em;
      align-items: center; } }

  &__links {
    text-transform: uppercase;
    display: grid;
    row-gap: 1em;

    &-item {
      color: $color-red; } }

  &__socials {
    display: flex;
    flex-wrap: wrap;

    &-item {
      display: block;
      width: 1.25em;
      height: 1.25em;

      margin-right: 0.5em;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      @include media-tablet {
        margin-right: 1.25em; }

      &--facebook {
        background-image: url("../images/icon-facebook.svg"); }
      &--instagram {
        background-image: url("../images/icon-instagram.svg"); }
      &--vk {
        background-image: url("../images/icon-vk.svg"); }
      &--youtube {
        background-image: url("../images/icon-youtube.svg"); }
      &--tiktok {
        background-image: url("../images/icon-tiktok.svg"); }
      &--telegram {
        background-image: url("../images/icon-telegram.svg"); }

      // &--facebook
      //   background-image: url("../images/icon-facebook.svg")
      // &--facebook
      //   background-image: url("../images/icon-facebook.svg")
      // &--facebook
      //   background-image: url("../images/icon-facebook.svg")

      &:last-of-type {
        margin-right: 0; } } }

  &__address {
    color: $grey-88; }

  &__phone {
    a {
      color: $dark-grey; } }

  &__email {
    a {
      @include fs14; } } }
