.profile {
  display: grid;
  row-gap: 2.5em;
  max-width: 79.625em;
  margin: 0 auto;

  color: $dark-grey;

  &__info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 6.6875em;
    align-items: center;

    &-left {}

    &-title {
      @include h24; }

    &-desc {
      margin-top: 0.75em; }

    &-progress {
      height: 0.5em;

      background-color: $grey-e5;
      border-radius: 0.125em;

      &-complete {
        position: relative;

        width: 50%;
        height: 0.5em;
        border-radius: 0.125em;

        background-color: $color-red;

        &::after {
          content: "";

          position: absolute;

          right: -0.0625em;
          top: 0;

          width: 0.875em;
          height: 1em;

          background-image: url("../images/info-progress.svg");
          background-repeat: no-repeat;
          background-size: contain; } }

      &-desc {
        @include fs11;

        position: absolute;
        top: 1.0909em;
        right: 0.7273em;

        span {
          color: $color-red; } } }

    &-right {
      &-desc {
        @include fs14;

        margin-top: 2.4375em; } }

    &-link {
      @include fs14;

      display: block;
      margin-top: 2.2857em;

      color: $dark-grey;
      font-weight: 800;

      span {
        color: $color-red; }

      &-arrow {
        position: relative;

        margin-top: 0.5em;
        height: 2px;

        background-color: $color-red;

        img {
          position: absolute;
          top: 50%;
          right: 0;

          transform: translateY(-50%); } } } }

  &__contacts {
    // display: grid
    // grid-template-columns: 1fr 23.6875em
    // grid-template-rows: auto auto 1fr
    // grid-template-areas: "form photo" "form desc" "form ."
    // column-gap: 2em
    // row-gap: 2em

    display: grid;
    /* grid-template-columns: 1fr 23.6875em; */
    /* grid-template-rows: auto auto 1fr; */
    grid-template-areas: "form";
    &-form-wrapper {
      grid-area: form; }

    &-photo {
      position: relative;

      grid-area: photo;

      &-btn {
        display: block;
        width: 11.25em;
        margin: 0 auto; }

      &-input {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;

        cursor: pointer;
        opacity: 0;

        z-index: 999; }

      &-img {
        position: relative;

        margin: 0 auto;
        width: 11.25em;
        height: 11.25em;

        overflow: hidden;
        border-radius: 50%;

        &:hover {
          &::before {
            background-color: rgba($dark-grey, 0); }

          &::after {
            opacity: 0; } }

        &::before,
        &::after {
          content: "";

          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0; }

        &::before {
          background-color: rgba($dark-grey, 0.33);
          transition: background 0.2s ease; }

        &::after {
          display: flex;

          background-image: url("../images/profile-avatar.svg");
          background-repeat: no-repeat;
          background-position: center;
          transition: opacity 0.2s ease; }

        img {
          width: 100%;
          height: 100%;

          object-fit: contain;
          object-position: center; } }

      &-desc {
        @include reset;

        margin-top: 1em;
        display: block;

        text-align: center;
        color: $grey-88;

        cursor: pointer; } }

    &-desc {
      grid-area: desc;
      row-gap: 0.25em;

      &-content {
        p {
          @include p14;

          maring: 0.7143em;

          &:first-child {
            margin-top: 0; }

          &:last-child {
            margin-bottom: 0; } } } }

    &-support {
      display: grid;
      row-gap: 0.25em;

      &-title {
        @include p14;

        color: $grey-c0; }

      &-body {
        @include p14;

        a {
          color: $color-red; }

        p {
          @include reset;

          color: $grey-c0; } } }

    &-title {
      @include h36;

      span {
        color: $color-red; } } } }
