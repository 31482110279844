.partners {
  padding: 2.5em 0 3.4375em;
  background-color: $grey-f0;

  @include media-tablet {
    padding: 2.5em 0; }

  @include media-desktop-1600 {
    padding: 3.3em 0; }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.875rem;

    @include media-tablet-h {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between; }

    @include media-desktop-1600 {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-flow: initial; }

    &--two-item {
      @include media-desktop-1600 {
        grid-template-columns: repeat(2, 1fr); }

      .partners__group--org {
        @include media-tablet-h {
          grid-row: auto; } } } }

  &__group {
    display: flex;
    align-items: center;

    &--org {
      column-gap: 1.5rem;

      @include media-tablet-h {
        grid-row: 1/3; }

      @include media-desktop-1600 {
        grid-row: auto; } }

    &--sup {
      flex-direction: column;
      row-gap: 1.25rem;

      @include media-tablet {
        flex-direction: row;
        column-gap: 1.5rem; }

      @include media-tablet-h {
        justify-content: flex-end; } }

    &--part {
      flex-direction: column;
      row-gap: 1.25rem;

      @include media-tablet {
        flex-direction: row;
        column-gap: 1.5rem; }

      @include media-tablet-h {
        justify-content: flex-end; } } }

  &__title {
    @include fs14;
    color: $grey;
    line-height: 1;

    @include media-tablet-h {
      @include fs16;
      line-height: 1; } }

  &__list {
    @include lreset;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1.5em;

    @include media-tablet-h {
      justify-content: center;
      width: initial; } }

  &__item {
    width: 5.625em;
    height: 3.375em;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      transform: translateZ(0); } } }
