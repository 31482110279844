.event-create-step-1 {
  display: grid;
  row-gap: 1.5em;

  @include media-tablet {
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(2, min-content);
    column-gap: 1.5em; }

  &__header {
    display: grid;
    row-gap: 0.25em; }

  &__title {
    @include fs14;

    color: $color-red;
    font-weight: 800;

    @include media-tablet {
      @include fs18; }

    span {
      color: $dark-grey;
      display: none;

      @include media-tablet {
        display: inline-block; } } }

  &__desc {
    @include fs18; }

  &__time-buttons {
    display: grid;
    row-gap: 0.75em;

    @include media-tablet {
      grid-column: 2;
      grid-row: 1 / span 2;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 1em;
      align-items: center; }

    label {
      @include fs14;

      border: 1px solid $grey-f5;
      background-color: $grey-f5;

      @include media-tablet {
        padding: 0 1.6429em; } }

    input {
      &:checked {
        &+label {
          border: 1px solid $color-red;
          background-color: $grey-f5; } } } }

  &__buttons {
    .btn {
      width: 100%;

      @include media-tablet {
        width: auto; } } } }
