.event-create-step {
  display: grid;
  row-gap: 1.5em;

  &__title {
    font-weight: 800;
    p {
      @include reset; }

    span {
      display: block;
      color: $color-red; } } }
