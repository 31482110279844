.event-create-step-3 {
  display: grid;
  row-gap: 1.5em;

  @include media-tablet {
    grid-template-columns: 1fr 26.125em;
    column-gap: 2.5em; }

  @include media-tablet-h {
    grid-template-columns: repeat(2, 1fr); }

  &__title {
    @extend .event-create-step-1__title; }

  &__desc {
    @extend .event-create-step-1__desc; }

  &__body {
    display: grid;
    row-gap: 1.5em;

    @include media-tablet {
      row-gap: 1em; } }

  &__textarea {
    textarea {
      @include p14;

      height: 11em;
      padding: 0.5714em 0.8571em 2.5714em; } }

  &__buttons {
    display: grid;
    row-gap: 0.75em;

    @include media-tablet {
      display: flex;
      justify-content: space-between;
      align-items: stretch; }

    .btn {
      margin-top: 0.8571em;

      @include media-tablet {
        flex-grow: 1;
        margin-top: 0; } } }

  &__cutaway-btn {
    display: flex;
    flex-grow: 1;

    a {
      @include fs11;

      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.6364em 0;
      width: 100%;

      color: $color-red;

      border-radius: 4px;
      border: 1px solid $color-red;

      @include media-tablet {
        flex-grow: 1;
        margin-right: 1.0909em; }

      img {
        width: 1.0909em;
        height: 1.0909em;
        margin-left: 0.5455em; } } }

  &__file-btn {
    @include fs11;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6364em 0;

    color: $color-red;

    border-radius: 4px;
    border: 1px solid $color-red;

    @include media-tablet {
      flex-grow: 1;
      margin-right: 1.0909em; }

    input {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      z-index: 99;
      cursor: pointer;

      width: 100%;

      opacity: 0; }

    img {
      width: 1.0909em;
      height: 1.0909em;
      margin-left: 0.5455em; } } }
