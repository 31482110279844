.cssload-container {
  display: block;
  width: 97px;
  margin: auto; }

.cssload-loading {
  i {
    display: inline-block;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    background: $color-black;

    &:nth-child(1) {
      animation: cssload-loading-ani1 1.15s ease-in-out infinite; }

    &:nth-child(2) {
      background: $red;
      margin-left: -10px;
      animation: cssload-loading-ani1 1.15s ease-in-out 0.58s infinite; } } }

@keyframes cssload-loading-ani1 {
  70% {
    transform: scale(0.5); } }
