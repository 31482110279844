.about {
  position: relative;
  padding-top: 5em;
  background-color: $grey-f0;
  overflow: hidden;

  @include media-tablet {
    padding-top: 4em; }

  @include media-tablet-h {
    padding-bottom: 4em; }

  .cont {
    padding: 0;

    @include media-tablet-h {
      padding-left: 1.875em;
      padding-right: 1.875em; }

    @include media-desktop {
      padding-left: 5em;
      padding-right: 5em; } }

  &__header {
    margin-bottom: 2.6875em;
    padding: 0 1.25em;
    color: $dark-grey;

    @include media-tablet {
      padding: 0 1.875em; }

    @include media-tablet-h {
      padding: 0; } }

  &__title {
    @include reset;
    @include h36;

    @include media-tablet {
      @include h48; }

    span {
      color: $green; } }

  &__desc {
    @include reset;
    @include p14;

    @include media-tablet {
      @include p16; } }

  &__list {
    @include lreset;

    @include media-tablet-h {
      display: flex;
      gap: 2em; } }

  &__item {
    position: relative;

    @include media-tablet-h {
      flex: 1 1;
      min-width: 18.75em;
      padding: 1.5em 1.5em 2.5em;
      border-radius: 1.25rem; }

    &--1,
    &--3 {
      background: #fff;

      &-title {
        color: $dark-grey; }

      &-desc {
        color: $dark-grey; }

      &-text {
        color: $dark-grey; } }

    &--2 {
      background: $dark-grey;

      &-title {
        color: #fff; }

      &-desc {
        color: #fff; }

      &-text {
        color: #fff; } }

    &-text {
      @include reset;
      @include p14;
      margin-bottom: 1.71em;

      @include media-tablet {
        margin-bottom: 2.857em; }

      @include media-tablet-h {
        padding: 0; } }

    &-content {
      display: grid;
      width: 100%;
      padding: 1.25em 1.25em 1.5em;

      @include media-tablet {
        grid-template-columns: 15.0625em auto;
        column-gap: 2.9375em;
        align-items: center;
        padding: 2em 1.875em; }

      @include media-tablet-h {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        padding: 0; }

      .btn {
        text-transform: uppercase;

        @include media-tablet-h {
          margin-top: auto; } } }

    &-title {
      @include reset;
      @include h24;
      margin-bottom: 0.83em;

      @include media-tablet {
        @include h36;
        grid-column: 1;
        grid-row: 1/4;
        margin-bottom: 0; }

      @include media-tablet-h {
        margin-bottom: 1.11em; }

      &--number {
        @include fs16;
        display: flex;
        align-items: center;

        @include media-tablet {
          @include h24; }

        span {
          margin-right: 0.6667em;
          font-size: 2.25em;

          @include media-tablet {
            margin-right: 0.375em;
            font-size: 2.6667em; }

          @include media-tablet-h {
            margin-right: 0.4167em; } } } }

    &-desc {
      @include reset;
      @include p14;
      margin-bottom: 0.857em;
      font-weight: 800;

      @include media-tablet {
        @include fs18; }

      @include media-tablet-h {
        margin-left: 0; } } } }
