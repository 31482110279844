.btn {
  @include fs14;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.5714em;
  min-width: 13.1429em;
  padding-left: 1.7143em;
  padding-right: 1.7143em;

  font-family: $base-font;
  font-weight: 400;

  border-radius: 0.25em;
  border: none;
  background-color: transparent;

  transition: all 0.2s linear;

  &--medium {
    @include fs16;

    height: 2.8125em;
    min-width: 14.5em;
    padding-left: 1.5em;
    padding-right: 1.5em;

    border-radius: 0.25em; }

  &--large {
    @include fs18;

    height: 3.0556em;

    padding-left: 4.1111em;
    padding-right: 4.1111em;

    border-radius: 0.25em; }

  &--small {
    @include h24;

    height: 2.8em;

    padding-left: 1.8em;
    padding-right: 1.8em;

    border-radius: 2.5em; }

  &--red {
    color: #ffffff;
    font-weight: 800;

    background-color: $red;

    &:hover {
      color: $grey-88;

      background-color: $grey-f5; } }
  &--blue {
    color: $color-black;
    font-weight: 800;

    background-color: $color-red;

    &:hover {
      color: $grey-88;

      background-color: $grey-f5; } }
  &--blue-white {
    color: #fff;
    font-weight: bold;

    background-color: $color-red;

    &:hover {
      color: $grey-88;

      background-color: $grey-f5; } }

  &--grey {
    color: $dark-grey;

    background-color: $grey-f5;

    &:hover {
      background-color: #ffffff;
      border: 1px solid $grey-c0; } }

  &--dark-grey {
    color: $dark-grey;

    background-color: $grey-e5;

    &:hover {
      color: $color-red;

      background-color: #ffffff;
      border: 1px solid $color-red; } }

  &--white {
    color: $grey-88;

    background-color: #ffffff;

    &:hover {
      color: $red; } }

  &--transparent {
    color: $grey-88;

    background: transparent;

    &:hover {
      color: $color-red; } }

  &--white-red {
    color: $red;
    font-weight: 800;

    background-color: #ffffff;
    border: 1px solid $red;

    &:hover {
      color: #ffffff;

      background-color: $red; } }
  &--white-blue {
    color: $color-red;
    font-weight: 800;

    background-color: #ffffff;
    border: 1px solid $color-red;

    &:hover {
      color: #ffffff;

      background-color: $color-red; } }

  &--white-grey {
    color: $grey-88;
    font-weight: 800;

    background-color: #ffffff;
    border: 1px solid $grey-88;

    &:hover {
      background-color: $grey-e5;
      border: none; } }
  &--white-grey-2 {
    color: $color-black;
    font-weight: 800;

    background-color: #ffffff;
    border: 1px solid $color-black;

    &:hover {
      background-color: $grey-e5;
      border: none; } }

  &--white-black {
    color: $color-black;
    font-weight: 800;

    background-color: #ffffff;
    border: 1px solid $color-black;

    &:hover {
      background-color: $grey-e5;
      border: none; } }

  &--stand {
    color: $color-black;

    background-color: $grey-e5;

    &:hover {
      color: #fff;
      font-weight: 800;

      background-color: $color-red; } }

  &--reg {
    color: white;
    text-transform: uppercase;
    font-weight: 800;

    background-color: $red;
    box-shadow: 0.2222em 0.2222em 0.6667em rgba(0, 0, 0, 0.15);

    &:hover {
      color: $grey-88;

      background-color: $grey-f5; } }

  &--schedule {
    @include fs18;

    height: 2.8125rem;
    display: grid;
    grid-template-columns: 1fr 2.8125rem;
    padding: 0;
    align-items: stretch;
    min-width: 0;
    overflow: hidden;

    color: $color-red;
    font-weight: 800;

    background-color: transparent;
    border: 1px solid $color-red;

    .btn {
      &__title {
        padding: 0 1em;
        display: flex;
        align-items: center;
        justify-content: center; }

      &__status {
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid $color-red;

        svg,
        path {
          fill: $color-red; } } }

    &:hover {
      background-color: $color-red;

      color: #fff;

      .btn {
        &__status {
          border-left: 1px solid #ffffff;

          svg,
          path {
            fill: #ffffff; } } } }

    &-subscribed {
      color: $grey-c0;
      background: transparent;
      border: 1px solid $grey-c0;

      .btn {
        &__title {
          &:nth-child(1) {}

          &:nth-child(2) {
            display: none; } }

        &__status {
          border-left: 1px solid $grey-c0;
          background: transparent;

          svg {
            &:first-child {
              path {
                fill: none;
                stroke: $color-red; } }

            &:last-child {
              display: none; } } } }

      &:hover {
        background-color: $grey-f5;

        .btn {
          &__title {
            &:nth-child(1) {
              display: none; }

            &:nth-child(2) {
              display: flex;

              color: $grey-c0; } }

          &__status {
            border-left: 1px solid $grey-c0;

            svg {
              &:first-child {
                display: none; }

              &:last-child {
                display: block;

                transform: rotate(45deg);

                path {
                  fill: $color-red; } } } } } } }

    &-watch {
      color: $color-red; } }

  &--table-schedule {
    @include fs11;

    width: 7.1875rem;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 1rem;
    align-items: center;
    padding: 0;

    &:hover {
      .btn {
        &__status {
          background-color: $color-red;

          svg,
          path {
            fill: #ffffff; } } } }

    .btn {
      &__title {
        color: $grey-88;
        text-align: right; }

      &__status {
        width: 2.125rem;
        height: 2.125rem;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        border: 1px solid $color-red;

        svg {
          width: 0.625rem;
          height: 0.625rem; }

        svg,
        path {
          fill: $color-red; } } }

    &-subscribed {
      .btn {
        &__title {
          &:nth-child(1) {}

          &:nth-child(2) {
            display: none; } }

        &__status {
          border: 1px solid $grey-c0;

          svg {
            &:first-child {
              path {
                fill: none;
                stroke: $grey-88; } }

            &:last-child {
              display: none; } } } }

      &:hover {
        .btn {
          &__title {
            &:nth-child(1) {
              display: none; }

            &:nth-child(2) {
              display: block; } }

          &__status {
            background-color: #ffffff;

            svg {
              &:first-child {
                display: none; }

              &:last-child {
                display: block;

                transform: rotate(45deg);

                path {
                  fill: $grey-88; } } } } } } } }
  &--yellow-small {
    @include fs14;
    background: $color-red;
    height: 2.6em;
    color: $color-black;
    font-weight: bold;
    padding-left: 1.7143em;
    padding-right: 1.7143em;
    &:hover {
      background-color: $yellow-calendar; } }
  // ! Ниже - не переписанные под стандарт кнопки
  &--grey-1 {
    font-weight: 400;
    background-color: $grey-e5;
    color: $grey-88; }

  &_icon {
    width: 1em;
    height: 1em;
    margin-left: 1em;
    object-position: center;
    object-fit: scale-down; }

  &--transparent-red {
    color: $red;
    font-weight: 400;
    border: $red 1px solid;
    background: none; }
  &--transparent-blue {
    color: $color-red;
    font-weight: 400;
    border: $color-red 1px solid;
    background: none; }

  &--transparent-c0 {
    color: $grey-c0;
    border: $grey-c0 1px solid;
    background: none; }

  &--new-field {
    position: relative;

    height: 2.7143em;
    color: $grey-88;
    font-weight: 400;

    background-color: $grey-f5;
    border: 1px solid $grey-e5;

    span {
      position: absolute;
      transform: translateY(-50%);

      width: 1em;
      height: 1em;

      &::before,
      &::after {
        content: "";

        position: absolute;

        background-color: $grey-88;
        border-radius: 20%; }

      &::before {
        width: 2px;
        height: 100%;
        left: 50%;
        transform: translateX(-50%); }

      &::after {
        width: 100%;
        height: 2px;
        transform: translateY(-50%); } } }

  &--skip {
    font-weight: 400;
    color: $grey-88; }

  &:disabled {
    background: $grey-e5 !important;
    color: $grey-c0 !important;
    cursor: auto; }
  &.disabled {
    background: $grey-e5 !important;
    color: $grey-c0 !important;
    cursor: auto; }

  &--filter-reset {
    color: $red;
    text-decoration: underline;
    // background-color: $grey-c0

    &-disabled {
      pointer-events: none; } }

  &--cutaway {
    color: $dark-grey;

    background-color: transparent;
    border-color: $grey-88;

    &-enabled {}
    &-disabled {
      color: $grey-c0;

      border-color: $grey-e5;
      pointer-events: none; }

    &-rejected {
      color: $grey-88;

      border-color: $grey-e5;
      pointer-events: none; } }

  &--red-underline {
    justify-content: flex-start;
    min-width: auto;
    padding: 0;
    color: $color-red;
    text-decoration: underline; } }
