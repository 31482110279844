.visitors {
  margin-top: 2.5em;
  padding: 0.5em 1.5em;

  border-radius: 0.75em;
  background-color: white;
  overflow: hidden;

  @include media-tablet {
    padding: 0.5em 2.5em 0; }

  @include media-desktop {
    padding: 1.1875em 4.8125em 0; }


  &__avatar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    display: none;
    width: 3em;
    height: 3em;

    object-position: center;
    object-fit: cover;

    border-radius: 50%;

    @include media-desktop {
      display: block;
      left: 20.33%; } }

  &__participant {
    position: relative;

    display: grid;
    grid-template-columns: 1fr 5.9375em;
    grid-template-areas: "index score" "name name";
    column-gap: 1em;
    align-items: center;
    padding: 0.75em 0;

    border-bottom: 0.0625em solid $grey-ee;

    @include media-tablet {
      grid-template-columns: 12.6562em 1fr 5.9375em;
      grid-template-areas: "index name score"; }

    @include media-tablet-h {
      grid-template-columns: 10.1875em 1fr 2.0582fr 5.9375em;
      grid-template-areas: "index name company score"; }

    @include media-desktop {
      padding: 1.3125em 0;
      grid-template-areas: "index name company score";
      grid-template-columns: 13.3125fr 19.375fr 28.75fr 5.9375em; }


    &:last-of-type {
      border-bottom: 0; }

    &-index {
      @include fs18;

      position: relative;

      grid-area: index;
      padding-left: 1.666em;

      font-weight: 800;
      color: $grey-c0;

      @include media-tablet {
        @include h24;

        padding-left: 0; }

      @include media-desktop {
        @include h36; }

      &::before {
        content: "";

        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;

        width: 1em;
        height: 1em;

        background-image: url(https://profi.travel/uploads/workshops/projects/otm2021/img/rating-leader-off.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        opacity: 0.3;

        @include media-tablet {
          left: 3.333em; }

        @include media-desktop {
          left: 3.138em;

          width: 0.666em;
          height: 0.666em; } } }


    &-name {
      @include fs14;

      grid-area: name;
      margin-top: 0.857em;

      font-weight: 800;

      @include media-tablet {
        margin-top: 0; }

      @include media-desktop {
        @include fs18;

        padding-right: 4.222em;
        padding-left: 3.555em; } }

    &-workplace {
      grid-area: company;
      display: none;

      @include media-tablet-h {
        display: block; } }

    &-avatar {
      flex-direction: column;
      align-items: flex-end;
      grid-area: score;
      justify-content: flex-end; }

    &-num {
      @include fs18;

      position: relative;

      color: $color-red;
      font-weight: 800;
      text-align: right;

      @include media-tablet {
        @include h24; }

      &::before {
        content: "+"; } }

    &-tint {
      @include fs11;

      font-weight: 800;
      text-align: right;
      text-transform: capitalize;

      @include media-tablet {
        @include fs14; } }

    &.leader {
      .visitors__participant {
        &-index {
          color: $color-red;

          &::before {
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            margin-top: 0;
            width: 1em;
            height: 1em;

            background-image: url(https://profi.travel/uploads/workshops/projects/otm2021/img/rating-leader.svg);
            opacity: 1;

            @include media-tablet {
              left: 3.333em; }

            @include media-desktop {
              left: 3.138em;

              width: 0.666em;
              height: 0.666em; } } } } } }

  &__pages {
    @include lreset;

    display: none;
    height: 3.25em;
    padding-left: 1.75em;
    padding-right: 1.75em;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.1875em;
    margin-left: -2.5em;
    margin-right: -2.5em;

    background-color: $grey-ee;

    @include media-tablet {
      display: flex; }

    @include media-desktop {
      margin-left: -4.8125em;
      margin-right: -4.8125em; }


    &-item {
      padding: 0.5em;

      color: $grey-c0;
      font-weight: 800;
      &.active {
        color: $color-red; } }

    &-separator {
      height: 1.625em;
      width: 0.0625em;

      background-color: $grey-c0; } }

  .preloader {
    position: relative;

    width: 40px;
    height: 40px;

    &__bg {
      position: absolute;
      left: 0;
      top: 0;

      display: none;
      justify-items: center;
      align-items: center;
      width: 100%;
      height: 100%;

      background: rgba(255, 255, 255, .85);
      z-index: 1; }

    &__dot {
      position: absolute;

      width: 6px;
      height: 6px;

      background: $grey-c0;
      border-radius: 50%;
      box-shadow: 0 3px 5px rgba(0, 0, 0, .15);
      transition: .3s;
      animation: preloader 1s infinite;
      opacity: .5;
      z-index: 1;

      &:nth-child(1) {
        left: 17px;
        top: 0; }


      &:nth-child(2) {
        left: 29px;
        top: 5px;
        animation-delay: .125s; }


      &:nth-child(3) {
        top: 17px;
        right: 0;
        animation-delay: .25s; }


      &:nth-child(4) {
        left: 29px;
        top: 29px;
        animation-delay: .375s; }


      &:nth-child(5) {
        left: 17px;
        bottom: 0;
        animation-delay: .5s; }


      &:nth-child(6) {
        left: 5px;
        top: 29px;
        animation-delay: .625s; }


      &:nth-child(7) {
        left: 0;
        top: 17px;
        animation-delay: .75s; }


      &:nth-child(8) {
        left: 5px;
        top: 5px;
        animation-delay: .875s; } } }
  &.processed {
    .preloader {
      &__bg {
        display: grid; } } } }
